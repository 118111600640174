import React from "react";
import { Link} from 'react-router-dom';
import { useState, useEffect } from "react";
import {City} from '../App';

interface CityCardProps{
    item: City;
};

let CityCard: React.FC<CityCardProps> = ({item}) => {


  return (
    <div className="card mycard1-content" style={{backgroundImage: 'url("/assets/images/cities/'+item.id+'.jpg")'}}>
      <div className="ambassador-card-name white-hero-tagline">{item.name}</div>
      <div className="div-block"></div>
      {item.comingSoon && <div className="button default-button card-cta">Coming Soon</div>}
      {!item.comingSoon && <Link to={"/"+item.name} className="button default-button card-cta w-button">Explore</Link>}
    </div>
  );
};



export default CityCard;
