import axios from 'axios';
import { useParams} from 'react-router-dom';
import React, {useEffect} from 'react';
import {Guide, Proposal, City} from '../App';
import GuideInfoPresent from '../components/GuideInfoPresent';



let GuideDetail: React.FC = () => {
  let {gid} = useParams();
  /////GET GUIDE DETAILS
  let [info, setInfo] = React.useState<Guide>({});
  useEffect(() => {
     getGuideInfo();
  }, []);

  let getGuideInfo = () => {
    axios.get<Guide>('guides/'+gid)
    .then((response) => {
      setInfo(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }

  let [proposals, setProposals] = React.useState<Proposal[]>([]);
  useEffect(() => {
    getAllProposals();
  }, []);

///GET PROPOSALS FOR SIDE PANNEL
  let getAllProposals = () => {
    axios.get<Proposal[]>('proposals/guide/'+gid)
    .then((response) => {
      setProposals(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }


  return (
    <>
      <GuideInfoPresent items={info}/>
    </>
  );


};

export default GuideDetail;
