import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Intro from '../components/Intro';
import {City} from '../App';

let Home: React.FC = () => {
  let [cities, setCities] = useState<City[]>([]);
  useEffect(() => {
    getCities();
  }, []);

  let getCities = () => {
    axios.get<City[]>('cities/active')
    .then((response) => {
      setCities(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }


  return (
    <>
      <Intro items={cities}/>
    </>
  );
};

export default Home;
