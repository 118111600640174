import GuideList from '../components/GuideList';
import {Guide} from '../App';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';



let Guides: React.FC = () => {
  let {city} = useParams();

  let [guides, setGuides] = React.useState<Guide[]>([]);
  useEffect(() => {
    getAllGuides();
  }, []);



  let getAllGuides = () => {
    axios.get<Guide[]>('guides/location/'+city)
    .then((response) => {
      setGuides(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }




  return (
    <>
      <GuideList items={guides}/>
    </>
  );
};

export default Guides;
