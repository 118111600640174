import React from "react";
import { Link} from 'react-router-dom';

let About: React.FC = () => {
  return (
    <div className="about-page">
      <div className="ambassador-bio">
        <p className="paragraph-about">Alice was asked by the White Queen in the novel “Through the Looking-Glass, and What Alice Found There” by Lewis Caroll:</p>
        <div className="div-block-2">
          <img src="/assets/images/Group.svg" loading="lazy" alt="" className="image" />
          <div className="text-block-8">“Holds the lid to the dish, while it lies in the middle.<br/>Which is easiest to do, Un-dish-cover the fish, or dishcover the riddle?"</div>
        </div>
        <p className="paragraph-about">giving also the inspiration for our name and direction…</p>
        <h1 className="heading-about-dishcover">Our mission</h1>
        <p className="paragraph-about">Dishcover was created to propose a novel approach in a place’s exploration through its flavours; in other words, it’s about tasting a place and its stories. We chose food because of its power to carry stories and bring people together and of course because we like it! But what is the flavour of a place? Is it about finding the “good” food spots or something more? We believe the latter, which is a conclusion we reached after lots of experimentation to ultimately being able to offer our signature, self-guided culinary walks and innovative local ambassador guides.</p>
        <h1 className="heading-about-dishcover">So, you might wonder what exactly these are</h1>
        <p className="paragraph-about">Our self-guided culinary walks offer an alternative experiential approach in local history learning or to put it better tasting, at your own pace. For instance, the “Athens in a Cup” tour, provides the opportunity to taste coffee at six of the best coffee shops in Athens and at the same time wander in 200 years of Athenian history sip by sip, by walking along the locations of legendary cafes.</p>
        <p className="paragraph-about">The local ambassador guides go one step further and present inspiring personas and their recommendations in the city from their own daily life; like asking a good friend. The recommendations include culinary spots, but also cultural ones and more, such as local independent fashion labels. But why locals? Because at the end of the day, the people are those spicing up a place. Our selection of ambassadors is handpicked, based on expertise and innovation, so the visitor can easily find unbiased, trusted recommendations, but also get in touch with the ideas and initiatives shaped or shaping the city and the people behind them.</p>
        <h1 className="heading-about-dishcover">Some advice on how to best use Dishcover</h1>
        <p className="paragraph-about">You should treat the guides and walks not as an exhaustive list of the “good” places, but as an invitation to explore further an area; an invitation to go beyond your comfort zone and off-the beaten path to explore new destinations, such as Alice. After all, this is why we are focusing on less visited neighbourhoods in established destinations or new, emerging destinations that are barely represented in travel tech giants.</p>
        <p className="paragraph-about">Sustainability, inclusivity and equal rights are integral part of our DNA. Particularly, we have designed the service in a way to have measurable, sustainable impact on the local communities it exists, while you will not find any place being featured that does not respect people equally, irrelevantly of sexual orientation, religion views or other such attributes. </p>
        <h1 className="heading-about-dishcover">Of course, we are happy to hear from you!</h1>
        <p className="paragraph-about">We always appreciate some constructive feedback and thus we encourage you to contact us and tell us what you liked, where you can see margin for further improvement or even to recommend a new inspiring local ambassador! Fell free to contact us <Link to={'/feedback'} className='recommended-by-link'>here</Link>.</p>
      </div>
    </div>
  );
};

export default About;
