import React, { useState} from 'react';
import axios from 'axios';
import {ContactUs} from '../App';


interface ContactProps {
    items: string;
};



let ContactForm: React.FC<ContactProps> = ({items}) => {
  let [email, setEmail] = useState<string>('');
  let [fullName, setFullname] = useState<string>('');
  let [text, setText] = useState<string>('');
  let submission: ContactUs = {
    id: '',
    email: '',
    message: '',
    fullName: ''
  };

  let submitExperience = () => {
    submission.fullName = fullName;
    submission.email = email;
    submission.message = text;
    axios.post('contactus',submission)
    .then((response) => {
      console.log(response);
    })
    .catch(error => console.error('Error: ${error}'));
  }



  return (
    <>
      <div className="contact-block">
        <div className="contact-text-block">Do you have some constructive feedback for us? Would you like to recommend a local ambassador? Or do you have another question? Whatever the reason, we are excited to hear from you!</div>
        <form className="contact-form">
            <ul>
                <li>
                    <label htmlFor="name"><span className="contact-span">Name <span className="required-star contact-span">*</span></span></label>
                    <input value={fullName} className="contact-us-inputs" onChange={e => setFullname(e.target.value!)} type="text" id="name" name="user_name" />
                </li>
                <li>
                    <label htmlFor="mail"><span className="contact-span">Email <span className="required-star contact-span">*</span></span></label>
                    <input value={email} className="contact-us-inputs" onChange={e => setEmail(e.target.value!)} type="email" id="mail" name="user_email" />
                </li>
                <li>
                    <label htmlFor="msg"><span className="contact-span">Message</span></label>
                    <textarea value={text} className="contact-us-inputs" onChange={e => setText(e.target.value!)} rows={4} cols={50}></textarea>
                </li>
                <li>
                    <input className="contact-us-inputs" type="submit" onClick={e => submitExperience()} />
                </li>
            </ul>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
