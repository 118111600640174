import React, {useEffect, useRef, RefObject} from 'react';
import ReactDOM from 'react-dom';
import {Proposal} from '../App';

//declare var google:any;
interface MapProps {
    lat?: number;
    long?: number;
    items: Proposal[];
    itemsRef?: RefObject<HTMLAnchorElement[]>;
};

let Mapbox: React.FC<MapProps> = ({lat,long,items,itemsRef}) => {
  let googleMap: google.maps.Map;
  let centerCoords = new google.maps.LatLng(Number(lat),Number(long));
  let markers: google.maps.Marker[] = [];
  let infowindow = new google.maps.InfoWindow();
  let isInitialMount = useRef(true);
  let prev;


  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
    else {
      initMap();
      placeMarkers(items);
    }
  }, [lat,long,items]);

  useEffect(() => {
    placeMarkers(items);
  },[items]);



  let placeMarkers = (markersToDraw: any) => {
    setMapOnAll(null);
    markers = [];
    for(let item of markersToDraw){
      //console.log('the marker',item);
      let markerCoords = new google.maps.LatLng(Number(item.lat),Number(item.lon));
      let marker = new google.maps.Marker({
        position: markerCoords,
        title: item.name,
        icon: "/assets/images/markerIcons/"+item.category+".svg"
      });
      markers.push(marker);
      let infowindowContent =   '<div class="info-window">'+
                                  '<div class="venue-title-wrapper">'+
                                  '<div class="venue-title">'+item.name+'</div>'+
                                  '<div class="venue-title-separator"></div>'+
                                  '</div>'+
                                  '<div class="venue-type">'+item.specialisesIn+'</div>'+
                                  '<div class="details-site-wrapper">'+
                                    '<img src="/assets/images/phone.svg" loading="lazy" alt="" />'+
                                    '<a href='+"tel:"+item.phone+' class="details-text">'+item.phone+'</a>'+
                                  '</div>'+
                                  '<div class="venue-details-divider"></div>'+
                                  '<div class="details-site-wrapper">'+
                                    '<img src="/assets/images/planet.svg" loading="lazy" alt="" />'+
                                    '<a href='+item.website+' target="_blank" class="details-text line-suppression">'+item.website+'</a>'+
                                  '</div>'+
                                  '<div class="venue-details-divider"></div>'+
                                  '<div class="details-site-wrapper">'+
                                    '<img src="/assets/images/location.svg" loading="lazy" alt="" />'+
                                    '<a href=https://www.google.com/maps/search/?api=1&query='+item.lat+'%2C'+item.lon+'  target="_blank" class="details-text">Open in Google Maps</a>'+
                                  '</div>'+
                                '</div>'


      google.maps.event.addListener(marker, "click", () => {
        if (window.innerWidth > 991) {
          infowindow.setContent(infowindowContent);
          infowindow.open(googleMap, marker);
        }
        focusProposal(item.id);
      });

      google.maps.event.addListener(googleMap, "click", () => {
        infowindow.close();
      });
    }
    setMapOnAll(googleMap);
  }

  let setMapOnAll = (gmap: google.maps.Map | null) => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(gmap);
    }
  }

  let focusProposal = (refId) => {
    if(prev){
      itemsRef.current[prev].classList.remove("venue-card-focus");
    }
    itemsRef.current[refId].scrollIntoView({behavior: "smooth", block: "center"});
    itemsRef.current[refId].classList.add("venue-card-focus");
    prev = refId;
  }

  let initMap = () => {
    centerCoords = new google.maps.LatLng(Number(lat),Number(long));
    googleMap = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: centerCoords,
      zoom: 14,
      disableDefaultUI: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{visibility: 'off'}],
        }
      ],
    });
  }


  return(
    <>
      <div className="wrapMap">
        <div id="map" className="mapBox"></div>
      </div>
    </>
  );
};

export default Mapbox;
