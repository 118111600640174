import React from "react";
import { Routes,Route, BrowserRouter as Router, useNavigate, useLocation, Navigate} from 'react-router-dom';
import Login from "./pages/Login";
import Home from './pages/Home';
import GuideDetail from './pages/GuideDetail';
import Guides from './pages/Guides';
import Proposals from './pages/Proposals';
import OurProjects from './pages/OurProjects';
import GeneralContact from './pages/GeneralContact';
import ProposalDetail from './pages/ProposalDetail';
import About from './pages/About';
import MainHeader from './components/MainHeader';
import MainFooter from './components/MainFooter';
import ScrollToTop from "./components/ScrollToTop";
import axios from 'axios';
import ReactGA from 'react-ga';



export interface City {
  id?: number;
  name: string;
  isActive?: boolean;
  comingSoon?: boolean;
  currencySign?: string;
  lat?: number;
  lon?: number;
}

export interface Guide {
  gid?: number;
  bioBody?: string;
  bioIntro?: string;
  dOpinion?: string;
  lat?: number;
  city?: City;
  lon?: number;
  name?: string;
  occupation?: string;
  profilePhoto?: string;
  quote?: string;
  surename?: string;
}

export interface Proposal {
  id?: number;
  name?: string;
	recommendedBy?: number;
	category?: string;
	subCategory?: string;
  addr?: string;
	lon?: string;
	lat?: string;
	priceRange?: string;
	specialisesIn?: string[];
  photos?: string[];
	description?: string;
	openingHours?: string[];
	familyFriendly?: boolean;
	lgbtFriendly?: boolean;
	freeWifi?: boolean;
	petsAllowed?: boolean;
  amea?: boolean;
  glutenFree?: boolean;
  parking?: boolean;
  vegan?: boolean;
  vegetarian?: boolean;
  acceptedCards?: string[];
	website?: string;
	phone?: string;
}

export interface Project {
  id?: number;
  title?: string;
  description?: string;
  photos?: string[];
  tags?: string[];
}

export interface ContactUs{
  id?: string;
  email?: string;
  message?: string;
  fullName?: string;
}

export interface UserData {
  username: string;
  email: string;
  roles: string[];
  token: string;
}

export interface AuthContextType {
  userData: UserData;
  signin: (username: String, password: String, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

export type PrevPath = {
  state: {
    from: Location;
  };
}

export let AuthContext = React.createContext<AuthContextType>(null!);
axios.defaults.baseURL = 'https://dishcovertravel.com:8443/app/api/';




export let AuthProvider = ({ children }: { children: React.ReactNode }) => {
  let [userData, setUserData] = React.useState<UserData>(null);
  let signin = (username: String, password: String, callback: VoidFunction) => {

    axios.post('auth/signin', {"username":username,"password":password})
      .then(res => {
        axios.defaults.headers.common['Authorization'] = 'Bearer '+res.data.accessToken;
        setUserData(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
        console.log('res data',res.data);
        callback();
      })
      .catch(error => {
        console.log(error);
        callback();
      });
  };

  let signout = (callback: VoidFunction) => {
    return signout(() => {
      setUserData(null);
      localStorage.removeItem("user");
      callback();
    });
  };
  let value = { userData, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export let useAuth = () => {
  return React.useContext(AuthContext);
}

export let AuthStatus = () => {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.userData) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome dude  !{" "}
      <button onClick={() => {auth.signout(() => navigate("/"));}}>Sign out</button>
    </p>
  );
}

export let RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.userData) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
/////////////////////////////////////////////////////////////////


let pageViewsTracking = (props) => {
  let pathname = props.match.path;
  let pageView;
  if(pathname==='*'){
    pageView = '/not-found';
  }
  else{
    pageView = pathname;
  }
  ReactGA.pageview(pageView, ['tracker1']);
}


let App: React.FC = () => {
  return(
    <>
      <Router>
        <ScrollToTop />
        <MainHeader />
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/:city" element={<RequireAuth><Guides/></RequireAuth>} />
            <Route path="/:city/:gid" element={<RequireAuth><GuideDetail/></RequireAuth>} />
            <Route path="/:city/:gid/proposals" element={<RequireAuth><Proposals/></RequireAuth>} />
            <Route path="/:city/:gid/proposals/:pid" element={<RequireAuth><ProposalDetail/></RequireAuth>} />
            <Route path="/feedback" element={<GeneralContact/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/projects" element={<OurProjects/>} />
          </Routes>
        </AuthProvider>
        <MainFooter />
      </Router>
    </>
  );
};

export default App;
