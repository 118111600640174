import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {Proposal, Guide} from '../App';
import { Link} from 'react-router-dom';
import Parser from 'html-react-parser';


interface ProposalInfoPresentProps{
    items:Proposal;
};


let ProposalInfoPresent: React.FC<ProposalInfoPresentProps> = ({items}) => {
  let defaultHeight = 192;
  let [heightCurrent, setHeightCurrent] = useState(defaultHeight);
  let [heightMax, setHeightMax] = useState(defaultHeight);
  let [heightMin, setHeightMin] = useState(defaultHeight);
  let [isExpanded, setIsExpanded] = useState(false);
  let [isOverflow, setIsOverflow] = useState(false);
  let [guideInfo, setGuideInfo] = React.useState<Guide>({});
  let [guideLocation, setGuideLocation] = React.useState<String>('');
  let slides = document.getElementsByClassName("slide") as HTMLCollectionOf<HTMLElement>;
  let slideIndex:number = 1;
  let isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
    else {
      getGuideInfo();
      getGuideLocation();
      showSlides(slideIndex);
    }
  }, [items]);

  ////// SHOW MORE/LESS STUFF


  useEffect(() => {
    let element = document.querySelector(".paragraphs-venue-info")
    let heightClient = element?.clientHeight || defaultHeight;
    let scrollClient = element?.scrollHeight || defaultHeight;
    if (heightClient !== scrollClient) {
      setIsOverflow(true);
      setHeightMax(scrollClient);
      setHeightMin(heightClient);
      setHeightCurrent(heightClient);
    }
  }, [items]);

  let handleClickBtn = () => {
    setHeightCurrent(isExpanded ? heightMin : heightMax);
    setIsExpanded((prev) => !prev);
  };



  /////GET GUIDE DETAILS
  let getGuideInfo = () => {
    axios.get<Guide>('guides/'+items.recommendedBy)
    .then((response) => {
      setGuideInfo(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }

  let getGuideLocation = () => {
    axios.get<String>('guides/'+items.recommendedBy+'/location')
    .then((response) => {
      setGuideLocation(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }

  let nextOrPrevSlide = (n) => {
    showSlides(slideIndex += n);
  }


  let showSlides = (n) => {
    if (n > slides.length){
      slideIndex = 1;
    }
    if (n < 1){
      slideIndex = slides.length;
    }
    for (let i = 0; i < slides.length; i++) {
         slides[i].style.display = "none";
    }
    slides[slideIndex-1].style.display = "block";
  }


  return (
      <div className="venue-page">
        <div className="detailed-half">
          <div className="carousel-container">
            <div className="venue-carousel-big">
              <div className="slider-big w-slider">
                <div className="mask-big w-slider-mask">
                  {items.photos && items.photos.map((photo,index) => (
                    <img key={index} src={'/assets/images/proposals/'+items.name.toLowerCase().replace(/\s/g, "").replace(/[&/\#,+()$~%.!'"’“”‘:*?<>{}]/g, '')+'/'+photo} className="slide fade w-slide" />
                  ))}
                </div>
                <a className="w-slider-arrow-left" onClick={e => nextOrPrevSlide(-1)}>
                  <div className="icon-2 w-icon-slider-left"></div>
                </a>
                <a className="w-slider-arrow-right" onClick={e => nextOrPrevSlide(1)}>
                  <div className="icon w-icon-slider-right"></div>
                </a>
                <div className="slide-nav w-slider-nav w-round"></div>
              </div>
            </div>
          </div>
          <div className="venue-bio-container">
            <h1 className="heading-h3">{items.name}</h1>
            <div className="venue-type">{items.specialisesIn}</div>
            <h2 className="heading-h-2">View other <Link to={"/"+guideLocation+"/"+guideInfo.gid+"/proposals"} className='recommended-by-link'>reccomendations</Link> by <Link to={"/"+guideLocation+"/"+guideInfo.gid} className='recommended-by-link'>{guideInfo.name} {guideInfo.surename}</Link>.</h2>
            <p className="paragraphs-venue-info" style={{ height: `${heightCurrent}px` }}>
              {Parser(String(items.description))}
            </p>
            {isOverflow && <a className="btn-toggle" onClick={() => handleClickBtn()}>{isExpanded ? "Show Less" : "Show More"}</a>}
          </div>
          <div className="good-to-know-container">
            <h1 className="heading-h4">Good to know</h1>
            <div className="tags-container">
              {items.freeWifi && <div className="tag-big w-button">Wifi</div>}
              {items.familyFriendly && <div className="tag-big w-button">Family</div>}
              {items.petsAllowed && <div className="tag-big w-button">Pets</div>}
              {items.amea && <div className="tag-big w-button">Accessibility</div>}
              {items.glutenFree && <div className="tag-big w-button">Gluten Free</div>}
              {items.parking && <div className="tag-big w-button">Parking</div>}
              {items.vegan && <div className="tag-big w-button">Vegan</div>}
              {items.vegetarian && <div className="tag-big w-button">Vegetarian</div>}
            </div>
          </div>
        </div>

        <div className="banner-half">
          <div className="venue-details">
            <h1 className="heading-h4">Details</h1>
            <div className="details-site-wrapper">
              <img src="/assets/images/phone.svg" loading="lazy" alt="" />
              <a href={'tel:'+items.phone} className="details-text line-suppression">{items.phone}</a>
            </div>
            <div className="venue-details-divider"></div>
            <div className="details-site-wrapper">
              <img src="/assets/images/planet.svg" loading="lazy" alt="" />
              <a href={items.website} target="_blank" className="details-text line-suppression">{items.website}</a>
            </div>
            <div className="venue-details-divider"></div>
            <div className="details-opening-time-wrapper">
              <img src="/assets/images/time.svg" loading="lazy" alt="" />
              <div className="details-text">
              {items.openingHours && items.openingHours.map((item,i) => (
                <div key={i}>{item}<br/></div>
              ))}
              </div>
            </div>
            <div className="venue-details-divider"></div>
            <div className="find-venue-button">
              <img src="/assets/images/find-venue.svg" loading="lazy" alt="" className="find-venue-button-icon" />
              <a href={"https://www.google.com/maps/search/?api=1&query="+items.lat+"%2C"+items.lon}  target="_blank" className="find-venue-button-text">Open in Google Maps</a>
            </div>
          </div>
        </div>
      </div>
);
};

export default ProposalInfoPresent;
