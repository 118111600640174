import { Link} from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";



let MainHeader: React.FC = () => {
  let [isScrolled, setIsScrolled] = useState(false);


  let handleScroll = (e) => {
    let pageOffset = window.scrollY;
    if (pageOffset > 15) {
      setIsScrolled(true);
    }
    else{
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    // initiate the event handler
    window.addEventListener('scroll', e => handleScroll(e), true);
    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener('scroll', e => handleScroll(e), true);
    }
  }, [])

  let burgerToggle = () => {
    let linksEl: HTMLElement = document.querySelector('.nav-links-narrow');
    if (linksEl.style.display === 'block') {
      linksEl.style.display = 'none';
    } else {
      linksEl.style.display = 'block';
    }
  }


  return (
      <div className={"navigation " + (isScrolled ? 'add-shadow' : '')}>
        <Link to="/home"><img src="/assets/images/Group-38.svg" loading="lazy" alt="" className="logo" /></Link>
        <div className="navigation-wide">
          <div className="nav-links">
            <Link to={'/home'} className="link-2">Home</Link>
            <Link to={'/feedback'} className="link-2">Contact Us</Link>
            <Link to={'/about'} className="link-2">About Us</Link>
          </div>
        </div>
        <div className="navigation-narrow">
          <button className="navbar-toggle" onClick={e => burgerToggle()}>
            <img src="/assets/images/hamburgerMenuIcon.svg" alt="" />
          </button>
          <div className="nav-links-narrow">
            <Link to={'/home'} className="link-2-narrow" onClick={e => burgerToggle()}>Home</Link>
            <Link to={'/feedback'} className="link-2-narrow" onClick={e => burgerToggle()}>Contact us</Link>
            <Link to={'/about'} className="link-2-narrow" onClick={e => burgerToggle()}>About us</Link>
          </div>
        </div>
      </div>
  );
};



export default MainHeader;
