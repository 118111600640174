import React from 'react';
import { Link} from 'react-router-dom';
import {Guide} from '../App';



interface GuideInfoPresentProps{
    items:Guide;
};


let GuideInfoPresent: React.FC<GuideInfoPresentProps> = ({items}) => {

  return (
      <div className="ambassador-page">
        <div className="ambassador-info">
          <img src={'/assets/images/guides/'+items.profilePhoto} className="ambassador-photo" alt="" />
          <h1 className="ambassador-name">{items.name} {items.surename}</h1>
          <p className="ambassador-title">{items.occupation}</p>
          {items.city && <Link to={"/"+items.city.name+"/"+items.gid+"/proposals"} className="primary-button-copy w-button">Recommendations</Link>}
          {items.city && <Link to={"/"+items.city.name+"/"} className="secondary-button-copy w-button">Other Guides</Link>}
        </div>
        <div className="ambassador-bio">
          <h1 className="heading-ambassador-bio">About {items.name}</h1>
          <p className="paragraph-ambassador-first-half">{items.bioIntro}</p>
          <div className="div-block-2"><img src="/assets/images/Group.svg" loading="lazy" alt="" className="image" />
            <div className="text-block-8">{items.quote}</div>
          </div>
          <p className="paragraph-ambassador-second-hakf">{items.bioBody}</p>
          {items.city && <Link to={"/"+items.city.name+"/"+items.gid+"/proposals"} className="primary-button-copy-copy w-button">Recommendations</Link>}
          {items.city && <Link to={"/"+items.city.name+"/"} className="secondary-button-copy-copy w-button">Other Guides</Link>}
        </div>
      </div>
    );
};

export default GuideInfoPresent;
