import React, {useState, useEffect} from 'react';
import { Link} from 'react-router-dom';
import {Guide} from '../App';

interface GuideListProps{
    items: Guide[];
};

let GuideList: React.FC<GuideListProps> = ({items}) => {
  let [searchText, setSearchText] = React.useState('');
  let [filteredSearch, setFilteredSearch] = React.useState<Guide[]>([]);

  useEffect(() => {
    setFilteredSearch(items);
  }, [items]);


  useEffect(() => {
    let tempSearchResult = items.filter(e => (e.name?.toLowerCase().includes(searchText) || e.surename?.toLowerCase().includes(searchText)));
    setFilteredSearch(tempSearchResult);
  }, [searchText]);

  return (
    <>
    <div className="cards">
      <div>
        <h1 className="heading-2">Pick your guide</h1>
        <div className="form-block w-form">
          <input type="search" value={searchText} className="search-bar w-input" placeholder='Filter guides...' onChange={e => setSearchText(e.target.value!)}/>
        </div>
      </div>
      <div className="cards-container">
        <div className="ambassador-grid">
        {filteredSearch.map(guide => (
          <div className="ambassador-card" key={guide.gid}>
            <img src={'/assets/images/guides/'+guide.profilePhoto} className="ambassador-card-image" />
            <div className="ambassador-card-information">
              <div className="ambassador-name-and-description">
                <div className="ambassador-card-name tagline-promo-40">{guide.name} {guide.surename}</div>
                <p className="about-ambassador">{guide.bioIntro}</p>
              </div>
              <div className="ambassador-card-actions">
                <Link to={"/"+guide.city.name+"/"+guide.gid} className="primary-button w-button">Read More</Link>
                <Link to={"/"+guide.city.name+"/"+guide.gid+"/proposals"} className="secondary-button w-button">Recommendations</Link>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default GuideList;
