import { Link} from 'react-router-dom';
import React, { useState} from 'react';





let MainFooter: React.FC = () => {
  let [email, setEmail] = useState<string>('');

  return (
    <div className="footer wf-section">
      <div className="footer-container">
        <div className="footer-content">
          <div className="w-layout-grid footer-links">
            <Link to={"/home"} className="link">Home</Link>
            <Link to={"/about"} className="link">About Us</Link>
            <Link to={"/feedback"} className="link">Contact Us</Link>
          </div>
          <div className="footer-copyright">
            <div className="rich-text-block-2 w-richtext">
              <p>© 2021 Dishcover. All right reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default MainFooter;
