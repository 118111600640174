import ProposalList from '../components/ProposalList';
import Mapbox from '../components/Mapbox';
import {Proposal} from '../App';
import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import {useParams } from 'react-router-dom';
import {Guide} from '../App';




let Proposals: React.FC = () => {
  let {gid} = useParams();
  let [info, setInfo] = React.useState<Guide>({});
  let [proposals, setProposals] = React.useState<Proposal[]>([]);
  let [filteredSearch, setFilteredSearch] = useState<Proposal[]>([]);
  let itemsRef = useRef<HTMLAnchorElement[]>([]);


  useEffect(() => {
     getGuideInfo();
     getAllProposals();
  }, []);

  let getGuideInfo = () => {
    axios.get<Guide>('guides/'+gid)
    .then((response) => {
      setInfo(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }

  let getAllProposals = () => {
    axios.get<Proposal[]>('proposals/guide/'+gid)
    .then((response) => {
      setProposals(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }

  return (
    <>
      <div className="map-page">
        <ProposalList gid={gid} gname={info.name} items={proposals} filteredSearch={filteredSearch} setFilteredSearch={setFilteredSearch} itemsRef={itemsRef}/>
        <Mapbox lat={info.lat} long={info.lon} items={filteredSearch} itemsRef={itemsRef}/>
      </div>
    </>
  );
};

export default Proposals;
