import axios from 'axios';
import {Proposal} from '../App';
import {Link} from 'react-router-dom';
import React, {useEffect, useRef, useState,RefObject} from 'react';
import FilterList from './FilterList';



interface ProposalListProps{
    gid?: string;
    gname?: string;
    items: Proposal[];
    filteredSearch?: Proposal[];
    setFilteredSearch: any;
    itemsRef: RefObject<HTMLAnchorElement[]>
};

let ProposalList: React.FC<ProposalListProps> = ({gid,gname,items,filteredSearch,setFilteredSearch,itemsRef}) => {
  let [guideLocation, setGuideLocation] = useState<string>("");
  let [filterList, setFilterList] = useState<any[]>([]);
  let [searchText, setSearchText] = useState('');
  let [filterShow, setFilterShow] = useState(false);
  let [mapShow, setMapShow] = useState(true);
  let isInitialMount = useRef(true);
  let [isScrolled, setIsScrolled] = useState(false);

//////////// SCROLL STUFF
  let handleScroll = (e) => {
    let pageOffset = window.pageYOffset;
    if (pageOffset > 10) {
      setIsScrolled(true);
    }
    else{
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    // initiate the event handler
    window.addEventListener('scroll', e => handleScroll(e), true);
    // this will clean up the event every time the component is re-rendered
    return () => {
      window.removeEventListener('scroll', e => handleScroll(e), true);
    };
  }, [])

  let scrollToTop = () => {
    document.querySelector("body").scrollIntoView({behavior: 'smooth'});
  };

  ////////// GENERAL SANITY CHECKS

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
    else {
      getGuideLocation();
      setFilteredSearch(items);
    }
    let uniques = [...new Set(items.map(item => item.category))];
    setFilterList(uniques);
  }, [items]);


  /////GET GUIDE DETAILS

  let getGuideLocation = () => {
    axios.get<string>('guides/'+gid+'/location')
    .then((response) => {
      setGuideLocation(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }

  ////// TOGGLING STATES

  let toggleFilters = () => {
    setFilterShow(!filterShow);
  }


  ///// SEARCH/ FILTERING STUFF

  let enableFilter = (filterArray: string[]) => {
    let filterResult = items.filter(item => filterArray.includes(item.category));
    setFilteredSearch(filterResult);
    setSearchText('');
    toggleFilters();
  }

  useEffect(() => {
    let tempSearchResult = items.filter(e => e.name?.toLowerCase().includes(searchText));
    setFilteredSearch(tempSearchResult);
  }, [searchText]);


  return (
    <>
      <div className="wrap">
        <h1 className="heading-3">
        <Link to={"/"+guideLocation+"/"+gid} className='recommended-by-link'>{gname}'s</Link> recommendations</h1>
        <div className="form-block w-form">
          <input type="search" value={searchText} className="search-bar w-input" placeholder={"Filter "+gname+"'s recommendations...'"} onChange={e => setSearchText(e.target.value!)}/>
        </div>
        <button className="filter" onClick={e => toggleFilters()}>
          <img src="/assets/images/filter.svg" loading="lazy" width="21" alt="" className="filter-icon"/>
          <div className="filter-label">Filters</div>
        </button>
        <FilterList toggleFilters={toggleFilters} show={filterShow} submitFilter={enableFilter} items={filterList} />
        <div className="separator-sidebar"></div>
        {filteredSearch.sort((a, b) => a.name.localeCompare(b.name)).map((proposal,index) => (
          <Link to={"/"+guideLocation+"/"+gid+"/proposals/"+proposal.id} className="venue-card" key={proposal.id} ref={e => itemsRef.current[proposal.id] = e}>
              <div className="venue-carousel">
                <div className="slider w-slider">
                  <div className="mask w-slider-mask">
                    <img src={"/assets/images/proposals/"+proposal.name.toLowerCase().replace(/\s/g, "").replace(/[&/\#,+()$~%.!'"’“”‘:*?<>{}]/g, '')+"/"+proposal.photos[0]} className="slide-2 w-slide" />
                  </div>
                </div>
              </div>
              <div className="venue-info">
                <div className="venue-title-wrapper">
                  <div className="venue-title">{proposal.name}</div>
                  <div className="venue-title-separator"></div>
                </div>
                <p className="paragraph">{proposal.description}</p>
                <div className="tags">
                  {proposal.freeWifi && <div className="tag">Wifi</div>}
                  {proposal.familyFriendly && <div className="tag">Family</div>}
                  {proposal.petsAllowed && <div className="tag">Pets</div>}
                  {proposal.amea && <div className="tag">Accessibility</div>}
                  {proposal.glutenFree && <div className="tag">Gluten Free</div>}
                  {proposal.parking && <div className="tag">Parking</div>}
                  {proposal.vegan && <div className="tag">Vegan</div>}
                  {proposal.vegetarian && <div className="tag">Vegetarian</div>}
                </div>
              </div>
          </Link>
        ))}
      </div>
      {isScrolled && window.screen.width<992 && (
        <button onClick={scrollToTop} className="back-to-top"><img src="/assets/images/backtotop.svg" loading="lazy" alt="" /></button>
      )}
    </>
  );};

export default ProposalList;
