import React from "react";
import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import CityCard from './CityCard';
import {City} from '../App';

interface IntroProps{
    items: City[];
};

let Intro: React.FC<IntroProps> = ({items}) => {
  let xDown = null;
  let yDown = null;
  let [goToSlide, setGoToSlide] = useState(null);
  let cities = items.map((element, index) => {
    return {key:index, content: <CityCard item={element}/>, name: element, onClick: () => setGoToSlide(index) };
  });

  let getTouches = (evt) => {
    return (evt.touches || evt.originalEvent.touches);
  };

  let handleTouchStart = (evt) => {
    let firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  let handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }
    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        setGoToSlide(goToSlide + 1);
      } else {
        /* right swipe */
        setGoToSlide(goToSlide - 1);
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  return (
    <div className="hero-section">
      <div className="container-hero">
        <div className="flexbox">
          <div className="left-block">
            <h1 className="heading">Taste the city and its stories</h1>
            <div className="text-block">Dare to go off the beaten path and explore the city through senses, with our signature, self-guided culinary walks and our exclusive local ambassadors’ guides.</div>
          </div>
          <div className="right-block" onTouchStart={(evt) => handleTouchStart(evt)} onTouchMove={(evt) => handleTouchMove(evt)}>
            <Carousel slides={cities} goToSlide={goToSlide} offsetRadius={2} showNavigation={false} animationConfig={config.gentle} />
          </div>
        </div>
      </div>
    </div>
  );
};



export default Intro;
