import React, { useState, useEffect, useRef } from "react";
import { Link} from 'react-router-dom';

interface FilterListProps {
  toggleFilters: any;
  show: boolean;
  submitFilter: any;
  items: string[];
};

interface FilterArray {
  name?:string;
  isChecked?:boolean;
}

let FilterList: React.FC<FilterListProps> = ({toggleFilters,show,submitFilter,items}) => {
  let [filterList, setFilterList] = React.useState<FilterArray[]>([]);
  let [filterOutput, setFilterOutput] = React.useState<string[]>([]);
  let showHideClassName = show ? "div-block-3" : "div-block-3 div-block-3-hide";
  let toggleOverlay = show ? 'modal-overlay' : '';


////INIT STATE
  useEffect(() => {
    for(let i=0;i<items.length;i++){
      filterList[i] = {name: items[i], isChecked: true};
      filterOutput[i] = items[i];
    }
  }, [items])



///// EVERY TIME A CHECKBOX IS TAPPED
  let onCheck = (e,index) =>{
    filterOutput = [];
    filterList[index].isChecked = !filterList[index].isChecked;
    for(let i=0;i<filterList.length;i++){
      if(filterList[i].isChecked){
          filterOutput.push(filterList[i].name);
      }
    }
  }

  ///// SUBMIT BUTTON FUNC
  let onSubmit = (e) =>{
      e.preventDefault();
      submitFilter(filterOutput);
      toggleFilters();
  }

  return(
    <>
      <div className={toggleOverlay}>
        <div className={showHideClassName}>
          <div className="filter-wrapper">
            <div className="text-block-9">Venue Type</div>
            <a onClick={e => toggleFilters()}><img src="/assets/images/menu-icon.svg" loading="lazy" alt=""/></a>
          </div>
          <div className="w-form">
            <form onSubmit={e => onSubmit(e)} className="form-2">
              {filterList.map((filter,index) => (
                <div key={index} className="w-checkbox checkbox-field-top">
                    <input type="checkbox" defaultChecked={filter.isChecked} value={filter.name} onChange={e => onCheck(e,index)}  className={"w-checkbox-input w-checkbox-input--inputType-custom" + (filter.isChecked ? 'w--redirected-checked' : '')}/>
                    <span className="checkbox-label w-form-label">{filter.name}</span>
                </div>
              ))}
              <input type="submit" value="Save Preferences" data-wait="Please wait..." className="submit-button-2 w-button"/>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



export default FilterList;
