import ContactForm from '../components/ContactForm';
import React from "react";

let GeneralContact: React.FC = () => {

  return (
    <>
      <div className="contact-page">
        <ContactForm items={'Feedback Request'}/>
      </div>
    </>
  );
};

export default GeneralContact;
