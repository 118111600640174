import { useAuth, PrevPath } from "../App";
import {useNavigate,useLocation} from "react-router-dom";
import React, {useEffect} from 'react';

let Login: React.FC = () => {

  let navigate = useNavigate();
  let location = useLocation() as unknown as PrevPath;
  let auth = useAuth();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    let username = "dishcovertravel";
    let password = "W3ar3D!shcov3r";
    auth.signin(username, password, () => {
      navigate(from, { replace: true });
    });
  }, []);


  let handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;
    auth.signin(username, password, () => {
      navigate(from, { replace: true });
    });
  }

  return(
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <label>
            Username: <input name="username" type="text" />
          </label>{""}
          <label>
            Password: <input name="password" type="password" />
          </label>{""}
          <button type="submit">Login</button>
        </form>
      </div>
    </>
  );
};


export default Login;
