import { IonGrid, IonRow, IonCol, IonContent, IonPage } from '@ionic/react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import React, {useEffect} from 'react';
import {Proposal} from '../App';
import ProposalInfoPresent from '../components/ProposalInfoPresent';


let ProposalDetail: React.FC = () => {
  let {pid} = useParams();
  /////GET GUIDE DETAILS
  let [info, setInfo] = React.useState<Proposal>({});
  useEffect(() => {
     getProposalInfo();
  }, []);

  let getProposalInfo = () => {
    axios.get<Proposal>('proposals/'+pid)
    .then((response) => {
      setInfo(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }



  return (

    <>
      <ProposalInfoPresent items={info}/>
    </>
  );


};

export default ProposalDetail;
