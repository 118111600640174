import React, {useEffect, useState, useRef} from 'react';
import {Project} from '../App';
import axios from 'axios';
import ProjectList from '../components/ProjectList';
import Parser from 'html-react-parser';

let OurProjects: React.FC = () => {
  let itemsRef = useRef<HTMLAnchorElement[]>([]);
  let [projects, setProjects] = useState<Project[]>([]);
  useEffect(() => {
    getProjects();
  }, []);

  let getProjects = () => {
    axios.get<Project[]>('projects')
    .then((response) => {
      setProjects(response.data);
      console.log(response.data);
    })
    .catch(error => console.error('Error: ${error}'));
  }


  return (
    <>
    <div className="map-page">
      <div>{Parser(String(projects[0].description))}</div>
    </div>
    </>
  );
};

export default OurProjects;
